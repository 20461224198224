export function getSubdomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');

    // Handle subdomain.localhost format
    if (parts.length === 2 && parts[1] === 'localhost') {
        return parts[0];
    }

    // Check if hostname is an IP address (IPv4 or IPv6)
    const ipv4Pattern = /^(\d{1,3}\.){3}\d{1,3}$/;
    const ipv6Pattern = /^([0-9a-fA-F]{0,4}:){2,7}[0-9a-fA-F]{0,4}$/;

    if (ipv4Pattern.test(hostname) || ipv6Pattern.test(hostname) || hostname === 'localhost' || parts.length <= 2) {
        return null;
    }

    // For subdomains like 'user1.get-to-know.me'
    return parts.slice(0, parts.length - 2).join('.');
}